'use strict';

angular.module('app.controllers')
    .controller('BrandsMenuCorrectionsCtrl',
    function ($scope, $state, Brand, User, $window,
              $timeout, $interval, $upload, $notification, confirm) {
        $scope.items = {};
        $scope.sections = {};

        _.each(['items', 'sections'], function (type) {
            $scope[type].csv = {
                file:    null,
                getFile: function () {
                    return this.file && this.file[0] || null;
                }
            };
        });

        $scope.brand = Brand.get({id: $state.params.id});
        $scope.items.corrections = Brand.MenuCorrection.query({brand_id: $state.params.id});
        $scope.sections.corrections = Brand.SectionCorrection.query({brand_id: $state.params.id});

        $scope.items.getExportArray = function () {
            if ($scope.items.corrections.length > 0) {
                return _.map($scope.items.corrections, function (element) {
                    return [
                        element.brand_id,
                        element.section_name,
                        element.original_name,
                        element.updated_name
                    ];
                });
            }

            return [[$state.params.id, '', '', '']];
        };
        $scope.items.getExportArray.headers = ['Brand ID', 'Section Name', 'Original Name', 'Updated Name'];

        $scope.sections.getExportArray = function () {
            if ($scope.sections.corrections.length > 0) {
                return _.map($scope.sections.corrections, function (element) {
                    return [
                        element.brand_id,
                        element.original_name,
                        element.updated_name
                    ];
                });
            }

            return [[$state.params.id, '', '']];
        };
        $scope.sections.getExportArray.headers = ['Brand ID', 'Original Name', 'Updated Name'];

        $scope.delete = function (correction, type) {
            confirm('Are you sure you want to delete this correction?')
                .then(function () {
                    if (type === 'items') {
                        correction.id = correction.section_name + '.' + correction.original_name;
                    } else {
                        correction.id = correction.original_name;
                    }
                    correction.$delete()
                        .then(function () {
                            _.remove($scope[type].corrections, function (element) {
                                return element === correction;
                            });
                        })
                        .catch(function (error) {
                            $notification.error('Could not delete correction: ' + JSON.stringify(error));
                        });
                });
        };

        $scope.import = function (type) {
            if ($scope[type].csv.getFile()) {
                $upload
                    .upload({
                        url:              [
                                              '/api/brands',
                                              $state.params.id,
                                              type === 'items' ? 'menu-corrections' : 'section-corrections',
                                              'import'
                                          ].join('/'),
                        file:             $scope[type].csv.file,
                        fileFormDataName: ['file']
                    })
                    .then(function (newCorrections) {
                        newCorrections = newCorrections.data;
                        $notification.info(
                            'File uploaded successfully. Inserted ' + newCorrections.length + ' rows'
                        );
                        $scope[type].csv.file = null;

                        _.each(newCorrections, function (item) {
                            $scope[type].corrections.push(
                                new Brand[type === 'items' ? 'MenuCorrection' : 'SectionCorrection'](item)
                            );
                        });
                    })
                    .catch(function () {
                        $notification.error('File upload failed');
                    });
            } else {
                $notification.error('Required csv file');
            }
        };

        User.query(function (users) {
            $scope.users = {
                list: users
            };

            _.forEach(users, function (user) {
                $scope.users[user.id] = user;
            });
        });
    }
);
