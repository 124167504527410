'use strict';

angular.module('app.controllers')
    .controller('UsersCtrl',
        function ($scope, User, ngTableParams, $filter, $modal) {
            let vm = $scope.vm = this;

            $scope.search = {
                term:    '',
                perform: function () {
                    $scope.tableParams.page(1);
                    $scope.tableParams.reload();
                }
            };

            $scope.users = User.query();

            $scope.tableParams = new ngTableParams(
                {
                    page:    1,            // show first page
                    count:   25,           // count per page
                    sorting: {
                        name: 'asc'     // initial sorting
                    }
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function ($defer, params) {
                        $scope.users.$promise.then(
                            function (users) {
                                var viewData = $filter('filter')(users, $scope.search.term);

                                $scope.bulk.all = false;

                                $scope.tableParams.total(viewData.length);

                                $scope.tableParams.custom.pages = $scope.tableParams.generatePagesArray(
                                    $scope.tableParams.page(),
                                    $scope.tableParams.total(),
                                    $scope.tableParams.count()
                                );

                                angular.forEach($scope.tableParams.sorting(), function (direction, attribute) {
                                    viewData = $filter('orderBy')(viewData, attribute, direction === 'desc');
                                });

                                viewData = viewData.slice(
                                    (params.page() - 1) * params.count(),
                                    params.page() * params.count()
                                );


                                $defer.resolve(viewData);
                            }
                        );
                    }
                });
            $scope.tableParams.custom = {};

            $scope.bulk = {
                action: 'activate',
                users:  {},
                all:    false,
                setAll: function () {
                    angular.forEach($scope.tableParams.data, function (user) {
                        $scope.bulk.users[user.user_id] = $scope.bulk.all;
                    });
                },
                apply:  function () {
                    var status;
                    if ($scope.bulk.action === 'activate') {
                        status = 'active';
                    } else {
                        status = 'pending';
                    }

                    angular.forEach($scope.users, function (user) {
                        if ($scope.bulk.users[user.user_id]) {
                            $scope.toggleStatus(user, status);
                            delete $scope.bulk.users[user.user_id];
                        }
                    });
                    $scope.bulk.all = false;
                }
            };

            $scope.toggleStatus = function (user, status) {
                if (status) {
                    user.status = status;
                } else {
                    user.status = user.status === 'active' ? 'pending' : 'active';
                }
                user.$save();
            };

            $scope.min = function (a, b) {
                return Math.min(a, b);
            };

            vm.managePermissions = user => {
                let modalInstance = $modal.open({
                    animation:   true,
                    templateUrl: 'views/users/permissions-modal.html',
                    controller:  'UserPermissionsCtrl',
                    size:        'lg',
                    resolve:     {
                        user: () => user
                    }
                });
            };
        }
    )
    .controller('UserPermissionsCtrl', function ($scope, $modalInstance, user, $notification) {
        let vm = $scope.vm = this;

        vm.user = user;

        vm.permissions = [
            {label: 'Full admin permissions', key: 'admin'},
            {label: 'Approve and publish recipes', key: 'recipes.approve'},
            {label: 'Access FOP transcription tasks', key: 'tasks.transcription.fop'},
            {label: 'Access Billing section', key: 'billing'},
            {label: 'Access 3Scale Developers', key: 'manage-developers'},
            {label: 'Access Email Templates', key: 'email-templates'},
            {label: 'Upload Tag Images', key: 'upload-tag-image'},
            {label: 'Bulk Data Export', key: 'bulk-export'},
            {label: 'Ingredient Learning', key: 'ingredient-learning'},
            {label: 'Brand Recipes Management', key: 'brand-recipes'},
            {label: 'Upload Translated Tags and Measures for locales', key: 'translations'},
        ];

        vm.userHasPermission = permission => user.permissions.indexOf(permission.key) > -1;

        vm.togglePermission = permission => {
            if (vm.userHasPermission(permission)) {
                user.permissions = _.without(user.permissions, permission.key);
            } else {
                user.permissions.push(permission.key);
            }
        };

        vm.save = () => {
            vm.save.$busy = true;
            user.$save()
                .then(() => {$notification.success('User permissions were updated'); })
                .catch(() => {$notification.error('User permissions could not be updated :(');})
                .finally(() => {vm.save.$busy = null;});
        };

        vm.close = () => $modalInstance.dismiss();

    });
