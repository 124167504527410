(function () {
  'use strict';

  const moduleName     = 'reports',
        controllerName = 'ReportBuilderCtrl';

  const moduleFullName = `app.modules.${moduleName}`;
  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ReportBuilderCtrl);

  /*@ngInject*/
  function ReportBuilderCtrl($scope, $http, $notification, $filter) {
    const vm = $scope.vm = this;

    vm.formOptions = {
      dataTypes:      ['CPG'],
      brandedRegions: [
        {value: 1, label: 'USA/CA'},
        {value: 2, label: 'UK'},
        {value: 3, label: 'AU'},
      ],
      boolean:        [
        {value: true, label: 'Yes'},
        {value: false, label: 'No'},
      ],
    }

    vm.report = {
      dataType:                       vm.formOptions.dataTypes[0],
      brandedRegions:                 vm.formOptions.brandedRegions.reduce((acc, region, index) => {
        acc[region.value] = {region: region.value, enabled: index === 0};
        return acc;
      }, {}),
      includeBackOfPantryProducts:    false,
      includeProductsMarkedAsDeleted: false,
    };

    vm.busy = false;

    Object.assign(vm, {
      isValid() {
        return Object.values(vm.report.brandedRegions).filter(r => r.enabled).length > 0;
      },
      async generateReport() {
        if (!this.isValid()) { return; }

        try {
          vm.busy = true;
          await $http.post('/reports/report-builder', vm.report);

          $notification.info(`Report generation started, you should receive the email soon at ${$scope.auth.profile.email}.`);
        } catch (e) {
          $notification.error(`Report generation  failed: ${$filter('readError')(e.data)}`);
        } finally {
          vm.busy = false;
        }
      },
    });
  }
}())
