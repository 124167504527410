(function () {
  'use strict';

  const moduleName     = 'force-transcription',
        controllerName = 'IndexCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, IndexCtrl);

  /*@ngInject*/
  function IndexCtrl($log, $http, $upload, $notification, $filter, $modal) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.addProductsFile          = new CsvUpload(
      '/api/tasks/force-transcription/add',
      data => vm.addProductsSuccessModal(data),
    );
    vm.impactAnalysisReportFile = new CsvUpload(
      '/api/tasks/force-transcription/impact-report',
      () => $notification.info(
        'Upload was successful and Impact Analysis Report is being generated. You should receive and email soon',
      ),
    );

    Object.assign(vm, {
      fileSelected(csvUpload) {
        try {
          csvUpload.select();
        } catch (e) {
          $notification.error('Unsupported file type. Please upload CSV files only');
        }
      },

      addProductsSuccessModal({added, skipped}) {
        $modal.open({
          templateUrl: 'force-transcription-add-products-success.html',
          controller:  function ($scope, $modalInstance) {
            $scope.vm = {
              added, skipped,
              ok() {
                $modalInstance.close();
              }
            }
          },
          size:        'lg',
        });
      }
    })

    vm.submit = async (csvUpload) => {
      vm.submit.$busy = true;

      try {
        const {data} = await $upload.upload({
          url:              csvUpload.uploadEndpoint,
          file:             csvUpload.uploadedFile,
          fileFormDataName: 'csv',
        });

        csvUpload.successCallback(data);

        csvUpload.clear();
      } catch (e) {
        console.log(e);
        $notification.error('File upload failed: ' + $filter('readError')(e.data));
      } finally {
        vm.submit.$busy = false;
      }
    };
  }

  class CsvUpload {
    constructor(uploadEndpoint, successCallback, accept = 'text/*') {
      this.uploadEndpoint  = uploadEndpoint;
      this.successCallback = successCallback;
      this.accept          = accept;

      this.clear();
    }

    clear() {
      this.uploadedFile = null;
      this.selected     = null;
      this.rejected     = null;
    }

    select() {
      if (this.rejected && this.rejected.length) {
        throw new Error('Unsupported file type');
      }

      if (this.selected && this.selected.length) {
        this.uploadedFile = this.selected[0];
      }
    }
  }
}());
