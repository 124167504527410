'use strict';

angular.module('app.controllers')
    .controller('BrandsEditCtrl',
      function ($scope, $state, Brand, $window, $timeout, $upload, $notification,
        navigationGuard, $modal, session, $filter, $http, confirm) {
        const vm = $scope.vm = this;

        vm.brandId = $state.params.id;
        vm.brandNamePattern = new RegExp('^[\\p{L}\\p{M}0-9 +#&:()*,!%"\'|$.\\/-]+$', 'u');
        vm.transcriptionStatuses = [
          { label: 'Not Started', value: 'NOT_STARTED' },
          { label: 'In Progress', value: 'IN_PROGRESS' },
          { label: 'Complete', value: 'COMPLETE' },
        ];
        $scope.usingFlash = window.FileAPI && FileAPI.upload != null;
        $scope.fileReaderSupported = window.FileReader != null &&
        (window.FileAPI == null || FileAPI.html5 != false);

        navigationGuard.watch(
            function () {
                return $scope.brandForm.$pristine;
            },
            'Not all operations where processed. Do you want to leave this page?'
        );

        navigationGuard.watch(function () {
            if($scope.brand && $scope.brand.assetCollections){
                for (var i = 0; i < $scope.brand.assetCollections.length; i += 1) {
                    if ($scope.brand.assetCollections[i].$form && $scope.brand.assetCollections[i].$form.$dirty) {
                        return false;
                    }
                }
            }

            return true;
        }, 'You have unsaved work on the page. Proceed?');

        $scope.brand = Brand.get({id: $state.params.id});

        vm.checkAllowEditLegacyId = () => {
            vm.allowEditLegacyId = !$scope.brand.legacy_nix_locations_id || session.profile.permissions.has('admin');
        };

        $scope.brand.$promise.then(vm.checkAllowEditLegacyId);


        $scope.save = function () {
            let post;
            $scope.save.$busy = true;
            $scope.save.error = false;

            ['desktop_calculator_url', 'mobile_calculator_url'].forEach((calculatorUrl) => {
                if ($scope.brand[calculatorUrl]) {
                    if (!($scope.brand[calculatorUrl].indexOf('http') === 0)) {
                        $scope.brand[calculatorUrl] = 'https://' + $scope.brand[calculatorUrl];
                    }

                    $scope.brand[calculatorUrl] = $scope.brand[calculatorUrl]
                        .replace('http://', 'https://')
                        .replace(/\/$/, '');

                    if (!$scope.brand[calculatorUrl].match(/\/nutrition-calculator$/)) {
                        $scope.brand[calculatorUrl] += '/nutrition-calculator';
                    }

                    $scope.brand[calculatorUrl] = $scope.brand[calculatorUrl]
                        .replace(/\/+/g, '/')
                        .replace('https:/', 'https://');
                } else {
                    $scope.brand[calculatorUrl] = null;
                }
            });

            if ($scope.newLogo[0]) {
                post = $upload.upload({
                    method: 'PUT',
                    url: '/api/brands/' + vm.brandId,
                    file:   $scope.newLogo[0],
                    fields: _.omit($scope.brand, ['$promise', '$resolved', 'assetCollections'])
                })
                    .then(function (response) {
                        return response.data;

                    });
            } else {
                post = Brand.save($scope.brand).$promise;
            }

            post
                .then(function (updatedBrand) {
                    $scope.brandForm.$setPristine();
                    $scope.brand = new Brand(updatedBrand);
                    $scope.newLogo = [];

                    vm.checkAllowEditLegacyId();
                })
                .catch(function (response) {
                    if (response.status === 409) {
                        $scope.save.error = {
                            type: 'conflict',
                            data: response.data.errors[0]
                        }
                    } else {
                        $scope.save.error = {
                            type:    'generic',
                            message: 'Unexpected backend error'
                        };
                    }
                })
                .finally(function () {
                    $scope.save.$busy = false;
                });
        };

        // logo upload
        $scope.newLogo = [];

        $scope.generateThumb = function (file) {
            if (file !== null) {
                if ($scope.fileReaderSupported && file.type.indexOf('image') > -1) {
                    $timeout(function () {
                        var fileReader = new FileReader();
                        fileReader.readAsDataURL(file);
                        fileReader.onload = function (e) {
                            $timeout(function () {
                                file.dataUrl = e.target.result;
                            });
                        };
                    });
                }
            }
        };

        $scope.uploadAsset = function uploadAsset(assetCollection, attribute) {
            var file;

            if (assetCollection[attribute] && assetCollection[attribute][0]) {
                file = assetCollection[attribute][0];

                $upload
                    .upload({
                        url:    '/api/brands/assets/' + assetCollection.id,
                        file:   file,
                        fields: {attribute: attribute}
                    })
                    .then(function (response) {
                        $notification.info('File uploaded successfully');
                        _.assign(assetCollection, response.data);
                    })
                    .catch(function () {
                        $notification.error('File upload failed');
                    });
            } else if (uploadAsset.rejectedFiles) {
                $notification.error('Incorrect file type');
                uploadAsset.rejectedFiles = null;
            }
        };

        /**
         * Nutrition Source Upload
         */

        $scope.uploadNutritionSource = async (files)=> {
            $scope.sourceUploadSuccess = false;
            $scope.sourceUploadProcessing = false;
            $scope.sourceUploadWarning = NaN;
            $scope.sourceUploadError = NaN;
            if (files && files.length && vm.brandId) {
             const [file] = files;
             try {
               await $upload.upload({
                 url: '/api/nutrition_source/upload/',
                 fields: {
                   'username': $scope.username,
                   'brand_id': vm.brandId
                 },
                 file: file
               });
               $scope.sourceUploadSuccess = true;
               const brandData = await Brand.get({ id: $state.params.id }).$promise;
               $scope.brand['assetCollections'] = brandData.assetCollections;
             } catch (error) {
               $notification.error($filter('readError')(error.data));
             }
            }
        };

        vm.getEventsList = async () => {
          const { data } = await $http.get(`/api/brands/${vm.brandId}/events?limit=10`);
          vm.events = data;
        }

        vm.getEventsList();

        vm.showNotes = false;

        vm.toggleNotes = () => {
          vm.showNotes = !vm.showNotes;
        };

        vm.getNotesList = async () => {
          const { data } = await $http.get(`/api/brands/${vm.brandId}/notes`);
          vm.notes = data;
        }

        vm.getNotesList();

        vm.openNotesModal = (note) => {
          $modal.open({
            animation: true,
            templateUrl: 'admin/brands/edit/add_notes.html',
            controller: 'NoteModalController',
            size: 'lg',
            resolve: {
              note: () => {
                return note;
              },
              brand: () => {
                return $scope.brand;
              },
              refreshNotes: () => () => vm.getNotesList()
            }
          });
        };

        vm.deleteNotes = async (noteId) => {
          confirm('Are you sure that you want to delete this note?')
            .then(async () => {
                await $http.delete(`/api/brands/${noteId}/notes`);
                $notification.info('Note has been deleted successfully.');
                vm.getNotesList()
            })
        }

        vm.viewNotes = function viewNotes(note) {
          $modal.open({
            animation: true,
            template: note,
            size: 'lg',
          });
        };

        vm.eventTypes = [];
        vm.getEventType = async () => {
          const { data } = await $http.get('/api/brands/event_types');
          vm.eventTypes = data;
          $scope.createEvent.init();
        }

        vm.getEventType();

        vm.getDescription = (eventId) => {
          const { description = '' } = vm.eventTypes.find(item => item.id === eventId) || {};
          return description;
        }

        $scope.createEvent = function createEvent() {
            $scope.newEvent.$save().then(function () {
                $notification.info('Event creation succeeded');
                vm.getEventsList();
                $scope.createEvent.init();
            }).catch(function () {
                $notification.error('Event creation failed');
            });
        };

        $scope.createEvent.init = function () {
          $scope.newEvent = new Brand.Event({
            brand_id: $state.params.id,
            type: vm.eventTypes[0] && vm.eventTypes[0].id
          });
          if ($scope.newEventForm) {
            $scope.newEventForm.$setPristine();
          }
        };

        $scope.saveAsset = function (asset) {
            if (asset.$form.$invalid) {
                return;
            }

            asset.$busy = true;
            Brand.Asset.save(asset).$promise.then(function () {
                $notification.info('saved');
                asset.$form.$setPristine();
            }).catch(function () {
                $notification.error('Asset save failed');
            }).finally(function () {
                asset.$busy = false;
            });
        };

        $scope.manageAliases = function manageAliases() {
            var modalInstance = $modal.open({
                animation:   true,
                templateUrl: 'admin/brands/edit/aliases_modal.html',
                controller:  'BrandsAliasesCtrl',
                size:        'lg',
                resolve:     {
                    brand: function () {
                        return $scope.brand;
                    }
                }
            });
        };

        $scope.mergeBrand = function mergeBrand() {
            var modalInstance = $modal.open({
                animation:   true,
                templateUrl: 'admin/brands/edit/merge_modal.html',
                controller:  'BrandsMergeCtrl',
                size:        'lg',
                resolve:     {
                    brand: function () {
                        return $scope.brand;
                    }
                }
            });
        };

        $scope.editDietitianGuide = function editDietitianGuide() {
            $scope.brand.$promise.then(function () {
                $state.go('app.brands.edit.dietitian-guide', {id: $state.params.id});
                if (!$scope.brand.guide) {
                    $scope.brand.guide = '';
                }
                var modalInstance = $modal.open({
                    animation:   true,
                    templateUrl: 'admin/brands/edit/dietitian_guide_modal.html',
                    controller:  'BrandsDietitianGuideCtrl',
                    size:        'lg',
                    resolve:     {
                        brand: function () {
                            return $scope.brand;
                        }
                    }
                });

                modalInstance.result.finally(function () {
                    $state.go('app.brands.edit', {id: $state.params.id});
                });
            });
        };

        if ($state.current.name === 'app.brands.edit.dietitian-guide') {
            $scope.editDietitianGuide();
        }

        vm.getTranscriptionData = async () => {
          const { data } = await $http.get(`/api/brands/${vm.brandId}/transcriptions`);
          vm.transcriptionList = data;
        };

        vm.getTranscriptionData();

        vm.saveTranscriptionStatus = async (transcription) => {
          const { sequence_number, status } = transcription;

          await $http.put(`/api/brands/${vm.brandId}/transcriptions/${sequence_number}`, { status });
          vm.getTranscriptionData();
          $notification.success(`Status has been updated successfully`);
        };

        vm.clearTranscriptionFile = async (sequence_number, status) => {

          await $http.put(`/api/brands/${vm.brandId}/transcriptions/${sequence_number}`, { status });
          vm.getTranscriptionData();
          $notification.success(`Transcription file has been removed successfully.`);
        };

        vm.uploadTranscriptionFile = async (files, sequenceNumber) => {
          if (files && files.length && sequenceNumber) {
            const [file] = files;
            try {
              await $upload.upload({
                method: 'PUT',
                url: `/api/brands/${vm.brandId}/transcriptions/${sequenceNumber}/file`,
                file
              });
              vm.getTranscriptionData();
              $notification.success('File uploaded successfully');
            } catch (error) {
              $notification.error($filter('readError')(error.data));
            }
          }
        };
    }
)
    .controller('BrandsAliasesCtrl', function ($scope, $modalInstance, Brand, brand, $notification, confirm, navigationGuard, $q) {
        $scope.brand = brand;
        $scope.aliases = Brand.Alias.query({brand_id: brand._id});
        var initNewAlias = function () {
            $scope.newAlias = new Brand.Alias({brand_id: brand._id});
        };

        initNewAlias();

        var guard = navigationGuard.watch(function () {
            var i;

            if ($scope.newAlias.$form && $scope.newAlias.$form.$dirty) {
                return false;
            }

            for (i = 0; i < $scope.aliases.length; i += 1) {
                if ($scope.aliases[i].$form && $scope.aliases[i].$form.$dirty) {
                    return false;
                }
            }
            return true;
        }, 'You have unsaved work. Do you really want to leave the page?');

        $scope.save = function save(alias) {
            var isNew = !alias.id;
            var form = alias.$form;

            if (!alias.alias) {
                $notification.error('Empty alias is not allowed');
                return false;
            }

            alias.$save()
                .then(function () {
                    if (isNew) {
                        $scope.aliases.push(alias);
                        initNewAlias();
                    }

                    $notification.info('Alias was saved');

                    if (form) {
                        form.$setPristine();
                    }
                    if ($scope.newAliasForm) {
                        $scope.newAliasForm.$setPristine();
                    }
                })
                .catch(function (response) {
                    if (response.status === 409) {
                        $notification.error(response.data.errors[0].message);
                    } else {
                        $notification.error("Saving failed");
                    }
                });
        };

        $scope.del = function del(alias) {
            confirm('Are you sure you want to delete alias ' + alias.alias + ' of brand ' + brand.name)
                .then(function () {
                    alias.$delete()
                        .then(function () {
                            var index = $scope.aliases.indexOf(alias);
                            if (index !== -1) {
                                $scope.aliases.splice(index, 1);
                            }

                            $notification.info('Alias deleted');
                        })
                        .catch(function () {
                            $notification.error('Deletion failed');
                        });
                });
        };

        $scope.close = function close() {
            $q.when(navigationGuard.check())
                .then(function (pass) {
                    return pass === true || confirm('You have unsaved work. Do you really want to close the dialog?');
                })
                .then(function () {
                    navigationGuard.unwatch(guard);
                    $modalInstance.dismiss('cancel');
                });
        };
    })
    .controller('BrandsMergeCtrl', function ($scope, $modalInstance, brand, confirm,
                                             suggest, $notification, $state, $http, $filter, $timeout) {
        const vm = $scope.vm = this;

        vm.brand   = brand;
        vm.suggest = suggest;

        vm.targetBrand = null;

        vm.save = function save() {
            if (vm.targetBrand) {
                confirm(
                    `This will transfer ${vm.brand.items_cnt} items from "${vm.brand.name}"
                    to "${vm.targetBrand.name}" and then delete "${vm.brand.name}"`
                )
                    .then(() => {
                        $http.put('/api/brands/merge', {source: vm.brand._id, target: vm.targetBrand._id})
                            .then(() => {
                                $notification.success(`Brand "${vm.brand.name}" has been merged into "${vm.targetBrand.name}"`);
                                $modalInstance.close();
                                $state.go('app.brands.edit', {id: vm.targetBrand._id});
                            })
                            .catch(response => {
                                $notification.error(`Brads could not been merged: ` + $filter('readError')(response.data));
                            })
                    });
            }
        };

        vm.close = function close() {
            $modalInstance.dismiss('cancel');
        };

        $timeout(() => vm.$focus = true, 500);
    })
  .controller('NoteModalController', function ($scope, $modalInstance, brand, note, refreshNotes, $notification, $http, $filter, $timeout) {
    const vm = $scope.vm = this;

    vm.modalTitle = note ? 'Update' : 'Add';
    vm.brand = brand;
    vm.newNotes = note ? angular.copy(note.notes) : '';

    vm.save = async () => {
      try {
        const apiCall = note ? $http.put(`/api/brands/${note.id}/notes`, { notes: vm.newNotes })
          : $http.post(`/api/brands/${vm.brand._id}/notes`, { notes: vm.newNotes });

        await apiCall;
        refreshNotes();
        const successMessage = note ? 'Note has been updated successfully.' : 'Note has been added successfully.';
        $notification.success(successMessage);

        $modalInstance.close();
      } catch (error) {
        $notification.error(`Something went wrong: ${$filter('readError')(error)}`);
      }
    };

      vm.close = () => {
        $modalInstance.dismiss('cancel');
      };

      $timeout(() => vm.$focus = true, 500);
    })
    .controller('BrandsDietitianGuideCtrl', function ($scope, $modalInstance, Brand, brand, $notification, confirm, navigationGuard, $q) {
        $scope.brand = brand;
        var originalValue = brand.guide;

        var guard = navigationGuard.watch(function () {
            return _.trim(brand.guide || '') === _.trim(originalValue || '');
        }, 'You have unsaved work. Do you really want to close the modal?');

        $scope.save = function save() {
            $scope.save.$busy = true;
            brand.$guideUpdated = true;
            Brand.save($scope.brand).$promise
                .then(function () {
                    $notification.info('Guide was saved');
                    originalValue = brand.guide;
                })
                .catch(function () {
                    $notification.error('Guide was not saved');
                })
                .finally(function () {
                    $scope.save.$busy = false;
                    brand.$guideUpdated = false;
                });
        };

        $scope.close = function close() {
            $q.when(navigationGuard.check())
                .then(function (pass) {
                    return pass === true || confirm('You have unsaved work. Do you really want to close the dialog?');
                })
                .then(function () {
                    navigationGuard.unwatch(guard);
                    $modalInstance.dismiss('cancel');
                });
        };
    });
